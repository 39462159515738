import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ShortGoalActions from '../actions/short-goals.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GoalsService } from 'src/app/shared/services/goals.service';

@Injectable()
export class ShortGoalEffects {
  constructor(
    private actions$: Actions,
    private shortGoalService: GoalsService
  ) {}

  // Load ShortGoals
  loadShortGoals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShortGoalActions.loadShortGoals),
      mergeMap(() =>
        this.shortGoalService.getShortGoals().pipe(
          map(goals => ShortGoalActions.loadShortGoalsSuccess({ goals })),
          catchError(error => of(ShortGoalActions.loadShortGoalsFailure({ error: error.message })))
        )
      )
    )
  );

  // Create ShortGoal
  createShortGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShortGoalActions.createShortGoal),
      mergeMap(action =>
        this.shortGoalService.createShortGoal(action.goal).pipe(
          map(goal => ShortGoalActions.createShortGoalSuccess({ goal })),
          catchError(error => of(ShortGoalActions.createShortGoalFailure({ error: error.message })))
        )
      )
    )
  );

  // Update ShortGoal
  updateShortGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShortGoalActions.updateShortGoal),
      mergeMap(action =>
        this.shortGoalService.updateShortGoal(action.goalId, action.goal).pipe(
          map(updatedShortGoal => ShortGoalActions.updateShortGoalSuccess({ goal: updatedShortGoal })),
          catchError(error => of(ShortGoalActions.updateShortGoalFailure({ error: error.message })))
        )
      )
    )
  );

  // Delete ShortGoal
  deleteShortGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShortGoalActions.deleteShortGoal),
      mergeMap(action =>
        this.shortGoalService.deleteShortGoal(action.goalId).pipe(
          map(() => ShortGoalActions.deleteShortGoalSuccess({ goalId: action.goalId })),
          catchError(error => of(ShortGoalActions.deleteShortGoalFailure({ error: error.message })))
        )
      )
    )
  );
}
