import { createReducer, on } from '@ngrx/store';
import * as ShortGoalActions from '../actions/short-goals.actions';
import { ShortGoal } from 'src/app/shared/types/goals';

export interface ShortGoalState {
  goals: ShortGoal[];
  error: string;
}

export const initialState: ShortGoalState = {
  goals: undefined,
  error: '',
};

export const shortGoalReducer = createReducer(
  initialState,
  on(ShortGoalActions.shortGoalSetInitial, state => initialState),

  // Load ShortGoals Success
  on(ShortGoalActions.loadShortGoalsSuccess, (state, { goals }) => ({
    ...state,
    goals,
    error: '',
  })),

  // Load ShortGoals Failure
  on(ShortGoalActions.loadShortGoalsFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Create ShortGoal Success
  on(ShortGoalActions.createShortGoalSuccess, (state, { goal }) => ({
    ...state,
    goals: [...state.goals, goal],
    error: '',
  })),

  // Create ShortGoal Failure
  on(ShortGoalActions.createShortGoalFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Update ShortGoal Success
  on(ShortGoalActions.updateShortGoalSuccess, (state, { goal }) => ({
    ...state,
    goals: state.goals.map(goal => (goal.id === goal.id ? goal : goal)),
    error: '',
  })),

  // Update ShortGoal Failure
  on(ShortGoalActions.updateShortGoalFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Delete ShortGoal Success
  on(ShortGoalActions.deleteShortGoalSuccess, (state, { goalId }) => ({
    ...state,
    goals: state.goals.filter(goal => goal.id !== goalId),
    error: '',
  })),

  // Delete ShortGoal Failure
  on(ShortGoalActions.deleteShortGoalFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
