import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CareerEssentialActions from '../actions/career-essentials.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GoalsService } from 'src/app/shared/services/goals.service';

@Injectable()
export class CareerEssentialEffects {
  constructor(
    private actions$: Actions,
    private careerEssentialService: GoalsService
  ) {}

  // Load CareerEssentials
  loadCareerEssentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CareerEssentialActions.loadCareerEssentials),
      mergeMap(() =>
        this.careerEssentialService.getCareerEssentials().pipe(
          map(careerEssentials => CareerEssentialActions.loadCareerEssentialsSuccess({ careerEssentials })),
          catchError(error => of(CareerEssentialActions.loadCareerEssentialsFailure({ error: error.message })))
        )
      )
    )
  );

  // Create CareerEssential
  createCareerEssential$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CareerEssentialActions.createCareerEssential),
      mergeMap(action =>
        this.careerEssentialService.createCareerEssential(action.careerEssential).pipe(
          map(careerEssential => CareerEssentialActions.createCareerEssentialSuccess({ careerEssential })),
          catchError(error => of(CareerEssentialActions.createCareerEssentialFailure({ error: error.message })))
        )
      )
    )
  );

  // Update CareerEssential
  updateCareerEssential$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CareerEssentialActions.updateCareerEssential),
      mergeMap(action =>
        this.careerEssentialService.updateCareerEssential(action.careerEssentialId, action.careerEssential).pipe(
          map(updatedCareerEssential => CareerEssentialActions.updateCareerEssentialSuccess({ careerEssential: updatedCareerEssential })),
          catchError(error => of(CareerEssentialActions.updateCareerEssentialFailure({ error: error.message })))
        )
      )
    )
  );
}
