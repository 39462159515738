import { createReducer, on } from '@ngrx/store';
import * as CareerEssentialActions from '../actions/career-essentials.actions';
import { CareerEssential } from 'src/app/shared/types/goals';

export interface CareerEssentialState {
  careerEssentials: CareerEssential[];
  error: string;
}

export const initialState: CareerEssentialState = {
  careerEssentials: [
    { field: 'confidence', quarter1: 0, quarter2: 0, quarter3: 0, quarter4: 0, note: '', order: 1 },
    { field: 'leadership', quarter1: 0, quarter2: 0, quarter3: 0, quarter4: 0, note: '', order: 2 },
    { field: 'communication', quarter1: 0, quarter2: 0, quarter3: 0, quarter4: 0, note: '', order: 3 },
    { field: 'negotiation', quarter1: 0, quarter2: 0, quarter3: 0, quarter4: 0, note: '', order: 4 },
    { field: 'time', quarter1: 0, quarter2: 0, quarter3: 0, quarter4: 0, note: '', order: 5 },
  ],
  error: '',
};

export const careerEssentialReducer = createReducer(
  initialState,
  on(CareerEssentialActions.careerEssentialSetInitial, state => initialState),

  // Load CareerEssentials Success
  on(CareerEssentialActions.loadCareerEssentialsSuccess, (state, { careerEssentials }) => ({
    ...state,
    careerEssentials: state.careerEssentials
      .map(initialEssence => {
        const matchingGoal = careerEssentials.find(g => g.field === initialEssence.field);
        // If there's a match, merge fields; otherwise, keep the initial goal unchanged
        return matchingGoal ? { ...initialEssence, ...matchingGoal } : initialEssence;
      })
      .sort((a, b) => a.order - b.order),
    error: '',
  })),

  // Load CareerEssentials Failure
  on(CareerEssentialActions.loadCareerEssentialsFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Create CareerEssential Success
  on(CareerEssentialActions.createCareerEssentialSuccess, (state, { careerEssential }) => ({
    ...state,
    careerEssentials: state.careerEssentials.map(initialEssence =>
      initialEssence.field === careerEssential.field ? { ...initialEssence, ...careerEssential } : initialEssence
    ),
    error: '',
  })),

  // Create CareerEssential Failure
  on(CareerEssentialActions.createCareerEssentialFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Update CareerEssential Success
  on(CareerEssentialActions.updateCareerEssentialSuccess, (state, { careerEssential }) => ({
    ...state,
    careerEssentials: state.careerEssentials.map(essential => (essential.id === careerEssential.id ? careerEssential : essential)),
    error: '',
  })),

  // Update CareerEssential Failure
  on(CareerEssentialActions.updateCareerEssentialFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
