import { createReducer, on } from '@ngrx/store';
import * as GoalStatusActions from '../actions/goal-statuses.actions';
import { GoalStatus } from 'src/app/shared/types/goals';

export interface GoalStatusState {
  goalStatuses: GoalStatus[];
  error: string;
}

export const initialState: GoalStatusState = {
  goalStatuses: [],
  error: '',
};

export const goalStatusReducer = createReducer(
  initialState,
  on(GoalStatusActions.LoadGoalStatusesSuccess, (state, { goalStatuses }) => ({
    ...state,
    goalStatuses,
    error: '',
  })),
  on(GoalStatusActions.LoadGoalStatusesFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
