<div class="flex flex-column justify-content-between h-full" *ngIf="items">
  <p-panelMenu
    id="menu"
    [style]="{ width: '260px', 'padding-top': '35px' }"
    [model]="items"
    (click)="highlight()"
    [ngClass]="smaller_font ? 'smaller-font' : null"
    class="flex"
    multiple="true"
  >
  </p-panelMenu>
  <div class="flex flex-column mb-5 pl-5 pr-2">
    <ng-container *ngIf="user_role !== 'CC'">
      <div>
        <h3 class="mb-0">Need help?</h3>
        <p class="m-0 inline vertical-align-top">
          <span (click)="contactEmail.toggle($event)">Contact</span> your program manager or
          <span (click)="reportEmail.toggle($event)">report</span> your technical issues.
        </p>
        <p-overlayPanel #contactEmail [dismissable]="true" [showCloseIcon]="true" [style]="{ 'margin-top': '10px' }">
          <div>mentoring&#64;femmepalette.com</div>
        </p-overlayPanel>
        <p-overlayPanel #reportEmail [dismissable]="true" [showCloseIcon]="true" [style]="{ 'margin-top': '10px' }">
          <div>help&#64;femmepalette.com</div>
        </p-overlayPanel>
      </div>
      <div class="pt-3 line-height-2">
        <ng-container *ngIf="user_role === 'ME' || user_role === 'CH'">
          <a href="https://www.femmepalette.com/refund" target="_blank" class="underline text-xs">Terms</a>,
          <a href="https://www.femmepalette.com/gdpr" target="_blank" class="underline text-xs">Privacy</a>,
          <a [href]="TERMS" target="_blank" class="underline text-xs">Platform Terms</a>
        </ng-container>
        <ng-container *ngIf="user_role === 'MR'">
          <a href="https://www.femmepalette.com/refund" target="_blank" class="underline text-xs">Terms</a>,
          <a href="https://www.femmepalette.com/gdpr" target="_blank" class="underline text-xs">Privacy</a>,
          <a [href]="TERMS" target="_blank" class="underline text-xs">Platform Terms</a>,
          <br />
          <a href="https://www.femmepalette.com/mentor-agreement" target="_blank" class="underline text-xs"> Mentor Agreement</a>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="user_role === 'CC'">
      <div>
        <h3 class="mb-0">Need help?</h3>
        <p class="m-0 inline vertical-align-top">
          <span (click)="contactEmail.toggle($event)">Contact</span> your Account Manager or
          <span (click)="reportEmail.toggle($event)">report</span> your technical issues.
        </p>
        <p-overlayPanel #contactEmail [dismissable]="true" [showCloseIcon]="true" [style]="{ 'margin-top': '10px' }">
          <div>lucia.z&#64;femmepalette.com</div>
        </p-overlayPanel>
        <p-overlayPanel #reportEmail [dismissable]="true" [showCloseIcon]="true" [style]="{ 'margin-top': '10px' }">
          <div>help&#64;femmepalette.com</div>
        </p-overlayPanel>
      </div>
      <div class="pt-3">
        <a href="https://www.femmepalette.com/gdpr" target="_blank" class="underline text-xs">Privacy</a>,
        <a [href]="TERMS" target="_blank" class="underline text-xs">Platform Terms</a>
      </div>
    </ng-container>
  </div>
</div>
