import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GoalsService } from 'src/app/shared/services/goals.service';
import * as GoalStatusActions from '../actions/goal-statuses.actions';

@Injectable()
export class GoalStatusEffects {
  constructor(
    private actions$: Actions,
    private goalStatusService: GoalsService
  ) {}

  loadGoalStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalStatusActions.LoadGoalStatuses),
      mergeMap(() =>
        this.goalStatusService.getGoalStatuses().pipe(
          map(goalStatuses => GoalStatusActions.LoadGoalStatusesSuccess({ goalStatuses })),
          catchError(error => of(GoalStatusActions.LoadGoalStatusesFailure({ error })))
        )
      )
    )
  );
}
