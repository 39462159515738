import { createReducer, on } from '@ngrx/store';
import * as CoachActions from '../actions/coach.actions';
import { Coach } from 'src/app/shared/types/coach.types';

const initialState: Coach = null;

export const coachReducer = createReducer(
  initialState,
  on(CoachActions.CoachSetInitial, state => initialState),

  on(CoachActions.CoachLoad, state => state),

  on(CoachActions.CoachLoadSuccess, (state, { coach }) => coach),

  on(CoachActions.CoachUpdateSuccess, (state, { coach }) => coach)
);
