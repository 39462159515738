import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { Feedback } from 'src/app/shared/types/feedback.types';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { Mentee } from 'src/app/shared/types/mentee.types';
import { Session } from 'src/app/shared/types/session.types';
import { Settings } from 'src/app/shared/types/settings.types';
import { environment } from '../../../environments/environment';
import * as feedbacksReducer from './feedbacks.reducer';
import * as menteeRelationsReducer from './mentee-relations.reducer';
import * as menteeReducer from './mentee.reducer';
import * as mentorReducer from './mentor.reducer';
import * as settingsReducer from './settings.reducer';
import * as sessionsReducer from './sessions.reducer';
import { Mentor } from 'src/app/shared/types/mentor.types';
import { City } from 'src/app/shared/types/city.types';
import { Country } from 'src/app/shared/types/country.types';
import { Environment } from 'src/app/shared/types/environment.types';
import { Field } from 'src/app/shared/types/field.types';
import { Gender } from 'src/app/shared/types/gender.types';
import { Language } from 'src/app/shared/types/language.types';
import { PersonalAttribute } from 'src/app/shared/types/personal-attribute.types';
import { Skill } from 'src/app/shared/types/skill.types';
import { Value } from 'src/app/shared/types/value-type.types';

import * as citiesReducer from './cities.reducer';
import * as countriesReducer from './countries.reducer';
import * as environmentReducer from './environments.reducer';
import * as fieldsReducer from './fields.reducer';
import * as genderReducer from './gender.reducer';
import * as languagesReducer from './languages.reducer';
import * as skillsReducer from './skills.reducer';
import * as teamSizeReducer from './team-sizes.reducer';
import * as personalAttributeReducer from './personal-attributes.reducer';
import { Company } from 'src/app/shared/types/company.types';
import * as companyReducer from './company.reducer';
import { Conversation } from 'src/app/shared/types/conversation.types';
import * as conversationReducer from './conversation.reducer';
import { Coach } from 'src/app/shared/types/coach.types';
import { coachReducer } from './coach.reducer';
import { Status } from 'src/app/shared/types/status.types';
import { statusesReducer } from './statuses.reducer';
import { shortGoalReducer, ShortGoalState } from './short-goals.reducer';
import { goalStatusReducer, GoalStatusState } from './goal-statuses.reducer';
import { longGoalReducer, LongGoalState } from './long-goals.reducer';
import { careerEssentialReducer, CareerEssentialState } from './career-essentials.reducer';

export interface AppState {
  cities: City[];
  coach: Coach;
  company: Company;
  conversations: Conversation[];
  countries: Country[];
  environments: Environment[];
  feedbacks: Feedback[];
  fields: Field[];
  gender: Gender[];
  languages: Language[];
  mentees: Mentee[];
  mentor: Mentor;
  menteeRelations: MenteeRelation[];
  personalAttributes: PersonalAttribute[];
  router: RouterReducerState;
  sessions: Session[];
  settings: Settings;
  skills: Skill[];
  statuses: Status[];
  teamSizes: Value[];
  // career planner
  goalStatuses: GoalStatusState;
  longGoals: LongGoalState;
  careerEssentials: CareerEssentialState;
  shortGoals: ShortGoalState;
}

export const reducers: ActionReducerMap<AppState> = {
  cities: citiesReducer.reducer,
  coach: coachReducer,
  company: companyReducer.reducer,
  conversations: conversationReducer.reducer,
  countries: countriesReducer.reducer,
  environments: environmentReducer.reducer,
  feedbacks: feedbacksReducer.reducer,
  fields: fieldsReducer.reducer,
  gender: genderReducer.reducer,
  languages: languagesReducer.reducer,
  mentees: menteeReducer.reducer,
  mentor: mentorReducer.reducer,
  menteeRelations: menteeRelationsReducer.reducer,
  personalAttributes: personalAttributeReducer.reducer,
  router: routerReducer,
  sessions: sessionsReducer.reducer,
  settings: settingsReducer.reducer,
  skills: skillsReducer.reducer,
  statuses: statusesReducer,
  teamSizes: teamSizeReducer.reducer,
  // career planner
  goalStatuses: goalStatusReducer,
  longGoals: longGoalReducer,
  careerEssentials: careerEssentialReducer,
  shortGoals: shortGoalReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
