import { createReducer, on } from '@ngrx/store';
import * as LongGoalActions from '../actions/long-goals.actions';
import { LongGoal } from 'src/app/shared/types/goals';

export interface LongGoalState {
  goals: LongGoal[];
  error: string;
}

export const initialState: LongGoalState = {
  goals: [
    { field: 'life', year1: '', year3: '', year5: '', year10: '', year15: '', order: 1 },
    { field: 'role', year1: '', year3: '', year5: '', year10: '', year15: '', order: 2 },
    { field: 'activity', year1: '', year3: '', year5: '', year10: '', year15: '', order: 3 },
    { field: 'skills', year1: '', year3: '', year5: '', year10: '', year15: '', order: 4 },
    { field: 'income', year1: '', year3: '', year5: '', year10: '', year15: '', order: 5 },
  ],
  error: '',
};

export const longGoalReducer = createReducer(
  initialState,
  on(LongGoalActions.longGoalSetInitial, state => initialState),

  // Load LongGoals Success
  on(LongGoalActions.loadLongGoalsSuccess, (state, { goals }) => ({
    ...state,
    goals: state.goals
      .map(initialGoal => {
        const matchingGoal = goals.find(g => g.field === initialGoal.field);
        // If there's a match, merge fields; otherwise, keep the initial goal unchanged
        return matchingGoal ? { ...initialGoal, ...matchingGoal } : initialGoal;
      })
      .sort((a, b) => a.order - b.order),
    error: '',
  })),

  // Load LongGoals Failure
  on(LongGoalActions.loadLongGoalsFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Create LongGoal Success
  on(LongGoalActions.createLongGoalSuccess, (state, { goal }) => {
    const updatedGoals = state.goals.map(initGoal => (initGoal.field === goal.field ? { ...initGoal, ...goal } : initGoal));
    return {
      ...state,
      goals: updatedGoals,
      error: '',
    };
  }),
  // Create LongGoal Failure
  on(LongGoalActions.createLongGoalFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Update LongGoal Success
  on(LongGoalActions.updateLongGoalSuccess, (state, { goal }) => ({
    ...state,
    goals: state.goals.map(initGoal => (initGoal.id === goal.id ? goal : initGoal)),
    error: '',
  })),

  // Update LongGoal Failure
  on(LongGoalActions.updateLongGoalFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
