import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  DL_SIGNUP_URL,
  FAREWELL,
  LOGINURL,
  MAINTENANCE,
  NOTFOUNDURL,
  PASSWORD_RESET_URL,
  SMARTGOALSGENERATOR,
  EMBEDGENERATOR,
  TERMS,
  LEADAUTH,
  RELOAD,
} from 'src/app/app-routing.module';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { LEADACTIVATION, PUBLICLOGIN, LEADSIGNUP } from 'src/app/authentication-lead/authentication-lead.module';

@Component({
  selector: 'fp-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store<AppState>,
    private utilsService: UtilsService,
    public authService: AuthenticationService
  ) {}
  currentUrl$ = this.store.select(state => state.router?.state?.url.split('?')[0]);

  async ngOnInit() {
    this.primengConfig.ripple = true;
    const user = await this.authService.getMe();
    if (user) {
      this.utilsService.loadData2Store(user?.role);
    }
  }

  loginUrls = [LOGINURL, DL_SIGNUP_URL, PASSWORD_RESET_URL, LEADAUTH, LEADSIGNUP, LEADACTIVATION, PUBLICLOGIN];
  noLayoutUrls = [NOTFOUNDURL, MAINTENANCE, TERMS, SMARTGOALSGENERATOR, EMBEDGENERATOR, FAREWELL, RELOAD];

  isLoginLayout(url: string): boolean {
    let urlNoSlash = url.slice(1);
    if (url.includes('activate')) {
      const segments = url.split('/');
      urlNoSlash = segments.slice(1, 3).join('/');
    }
    return this.loginUrls.includes(urlNoSlash);
  }

  isNoLayout(url: string): boolean {
    const urlNoSlash = url.slice(1);
    return this.noLayoutUrls.includes(urlNoSlash);
  }
}
