import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { Observable } from 'rxjs';
import { SETTINGS } from 'src/app/app-routing.module';
import { PUBLICLOGIN } from 'src/app/authentication-lead/authentication-lead.module';
import { roleMapping, User } from 'src/app/shared/types/user.types';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'fp-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnChanges {
  mrs$: Observable<MenteeRelation[]> = this.store.select(state => state.menteeRelations);
  mobile_menu: MenuItem[];
  @Input() items: MenuItem[];
  @Input() user: User;
  @Output() hideSidebar = new EventEmitter<string>();

  constructor(
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private router: Router,
    public store: Store<AppState>
  ) {
    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        this.hideSidebar.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      if (this.items) {
        this.mobile_menu = [
          ...this.items,
          {
            label: 'Settings',
            routerLink: SETTINGS,
          },
        ];
      }
    }

    let roles = this.user?.roles;
    if (this.user?.roles.length > 1) {
      // Filter out roles that are equal to the `user` role
      const filteredRoles = roles.filter(role => role !== 1 && roleMapping[role]?.code !== this.user?.role);
      // Create menu items based on filtered roles
      const menuItemsMoreRoles = filteredRoles.map(role => ({
        label: `${roleMapping[role]?.label} profile`,
        command: () => this.navigationService.changeUser(this.user, roleMapping[role].code),
      }));
      this.mobile_menu = this.mobile_menu ? [...menuItemsMoreRoles, ...this.mobile_menu] : this.mobile_menu;
    }
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate([PUBLICLOGIN]);
  }
}
