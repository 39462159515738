import { Mentee } from 'src/app/shared/types/mentee.types';
import * as MenteeActions from '../actions/mentee.actions';
import { cloneDeep } from 'lodash';

const initialState: Mentee[] = null;

export function reducer(state = initialState, action: MenteeActions.Actions): Mentee[] {
  let allMentees: Mentee[];
  let ix: number;
  allMentees = cloneDeep(state) ?? [];

  switch (action.type) {
    case MenteeActions.MENTEE_SET_INITIAL:
      return initialState;
    case MenteeActions.MENTEE_LOAD:
      return state;
    case MenteeActions.MENTEE_LOAD_SUCCESS:
      return action.payload;
    case MenteeActions.UPDATE_MENTEE_SUCCESS:
      ix = allMentees.findIndex(item => item.id === action.mentee.id);
      allMentees[ix] = { ...allMentees[ix], ...action.mentee };
      return allMentees;
    default:
      return state;
  }
}
