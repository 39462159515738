import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Observable, filter, first, lastValueFrom } from 'rxjs';
import {
  CLIENTPORTAL,
  COMMUNITY,
  COMPANY,
  DASHBOARD,
  MENTORDATABASE,
  NETWORKING,
  PROFILE,
  RELATIONS,
  REPORTS,
  RESOURCES,
  INNERSMARTGOALSGENERATOR,
  TASKTRACKER,
  CAREERPLANNER,
} from 'src/app/app-routing.module';
import { FBKSAWAITING, FINALOVERVIEW, FINALRATING, MATCHES, OVERALLRATING, PROGRESSRATING } from 'src/app/reports/reports.module';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { hasValue } from 'src/app/shared/utils';
import { AppState } from 'src/app/state/reducers';
import { selectLastMentee } from 'src/app/state/selectors/mentee.selector';

@Component({
  selector: 'fp-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(private store: Store<AppState>) {}
  bannerType: string;
  currentDate: Date = new Date();
  mrs$: Observable<MenteeRelation[]> = this.store.select(state => state.menteeRelations);
  role$: Observable<any>;
  role;
  visibleBanner: boolean = false;
  visibleSidebar: boolean;
  items: MenuItem[];
  RELATIONS = RELATIONS + '/';
  INNERSMARTGOALSGENERATOR = INNERSMARTGOALSGENERATOR;
  TASKTRACKER = TASKTRACKER;
  user$ = this.store.select(state => state.settings.user);

  async ngOnInit() {
    this.user$.subscribe(async user => {
      if (user?.role === 'ME') {
        this.role$ = this.store.select(selectLastMentee());
        const role$ = this.role$.pipe(filter(hasValue), first());
        this.role = await lastValueFrom(role$);
        this.currentDate.setDate(this.currentDate.getDate() - 7);
      } else if (user?.role === 'MR') {
        this.role$ = this.store.select(state => state.mentor);
        const role$ = this.role$.pipe(filter(hasValue), first());
        this.role = await lastValueFrom(role$);
        this.currentDate.setDate(this.currentDate.getDate() - 7);
        if (this.role?.status === 46) {
          this.visibleBanner = true;
          this.bannerType = 'offboarded';
        } else if (this.role?.status === 19) {
          this.visibleBanner = true;
          this.bannerType = 'notresposive';
        }
      } else if (user?.role === 'CH') {
        this.role$ = this.store.select(state => state.coach);
        const role$ = this.role$.pipe(filter(hasValue), first());
        this.role = await lastValueFrom(role$);
        this.currentDate.setDate(this.currentDate.getDate() - 7);
        if (this.role?.status === 18) {
          this.visibleBanner = true;
          this.bannerType = 'offboardedCoach';
        }
      } else if (user?.role === 'CC') {
        this.role$ = this.store.select(state => state.company);
        this.role$.subscribe(company => {
          if (company) {
            this.role = company;
            const today = this.currentDate.toISOString().split('T')[0];
            if (this.role?.onboarding_start && this.role?.due_dates) {
              if (this.role?.due_dates[1] < today) {
                this.visibleBanner = true;
                this.bannerType = 'final_due';
              } else if (this.role?.due_dates[0] < today) {
                this.visibleBanner = true;
                this.bannerType = 'mid_due';
              }
            }
          }
        });
      }

      if (this.role?.user_data?.date_joined > this.currentDate.toISOString() && user?.role !== 'CC') {
        this.visibleBanner = true;
        if (user?.role === 'CH') {
          this.bannerType = 'tutorial-coach';
        } else {
          this.bannerType = 'tutorial';
        }
      }
      if (user?.role === 'LD') {
        this.items = [
          {
            label: 'Carrer Planeer',
            routerLink: CAREERPLANNER,
          },
        ];
      } else if (user?.role === 'CC') {
        this.items = [
          {
            label: 'Dashboard',
            routerLink: COMPANY,
          },
          {
            label: 'Reports',
            items: [
              { label: 'Mentee-mentor matches', routerLink: REPORTS + '/' + MATCHES },
              { label: 'Overall rating', routerLink: REPORTS + '/' + OVERALLRATING },
              { label: 'Mentee rating - Progress', routerLink: REPORTS + '/' + PROGRESSRATING },
              { label: 'Mentee rating - Final', routerLink: REPORTS + '/' + FINALRATING },
              { label: 'Final overview', routerLink: REPORTS + '/' + FINALOVERVIEW },
              { label: 'Feedbacks awaiting', routerLink: REPORTS + '/' + FBKSAWAITING },
            ],
            expanded: false,
          },
          {
            label: 'Client Portal',
            routerLink: CLIENTPORTAL,
          },
        ];
      } else if (['ME', 'MR', 'CH'].includes(user?.role)) {
        this.mrs$.subscribe(mrs => {
          const mentoring_items =
            mrs
              ?.filter(menteeRelation => menteeRelation.mr_status === 10)
              .map(menteeRelation => ({
                label:
                  user?.role === 'ME'
                    ? `${menteeRelation.supporter.first_name} ${menteeRelation.supporter.last_name[0]}.`
                    : `${menteeRelation.mentee.first_name} ${menteeRelation.mentee.last_name[0]}.`,
                routerLink: [this.RELATIONS + menteeRelation.id],
              })) ?? [];

          const finished_mentoring_items =
            mrs
              ?.filter(menteeRelation => menteeRelation.mr_status !== 10 && menteeRelation.supporter.type === 'mentor')
              .map(menteeRelation => ({
                label:
                  user?.role === 'ME'
                    ? `${menteeRelation.supporter.first_name} ${menteeRelation.supporter.last_name[0]}.`
                    : `${menteeRelation.mentee.first_name} ${menteeRelation.mentee.last_name[0]}.`,
                routerLink: [this.RELATIONS + menteeRelation.id],
              })) ?? [];
          const coaching_items =
            mrs
              ?.filter(menteeRelation => menteeRelation.mr_status === 11)
              .map(menteeRelation => ({
                label:
                  user?.role === 'ME'
                    ? `${menteeRelation.supporter.first_name} ${menteeRelation.supporter.last_name[0]}.`
                    : `${menteeRelation.mentee.first_name} ${menteeRelation.mentee.last_name[0]}.`,
                routerLink: [this.RELATIONS + menteeRelation.id],
              })) ?? [];
          const finished_coaching_items =
            mrs
              ?.filter(menteeRelation => menteeRelation.mr_status !== 11 && menteeRelation.supporter.type === 'coach')
              .map(menteeRelation => ({
                label:
                  user?.role === 'ME'
                    ? `${menteeRelation.supporter.first_name} ${menteeRelation.supporter.last_name[0]}.`
                    : `${menteeRelation.mentee.first_name} ${menteeRelation.mentee.last_name[0]}.`,
                routerLink: [this.RELATIONS + menteeRelation.id],
              })) ?? [];

          const smartGoalsItem = user?.role === 'ME' ? [{ label: 'SMART goals', routerLink: [INNERSMARTGOALSGENERATOR] }] : [];
          const taskTrackerItem = user?.role === 'ME' ? [{ label: 'Tasks tracker', routerLink: [TASKTRACKER] }] : [];

          this.items = [
            ...(user?.role !== 'CH'
              ? [
                  {
                    label: 'Dashboard',
                    routerLink: DASHBOARD,
                  },
                ]
              : []),

            // extra menu item for mentor self-selection programme
            ...(mentoring_items.length === 0 &&
            finished_mentoring_items.length === 0 &&
            coaching_items.length === 0 &&
            finished_coaching_items.length === 0 &&
            user?.role === 'ME' &&
            this.role.programme == 5
              ? [{ label: 'Mentoring', routerLink: RELATIONS }]
              : []),

            // menu items for relations
            ...(mentoring_items.length !== 0
              ? [{ label: 'Mentoring', items: [...mentoring_items, ...smartGoalsItem, ...taskTrackerItem], expanded: true }]
              : []),

            ...(coaching_items.length !== 0 ? [{ label: 'Coaching', items: coaching_items, expanded: true }] : []),

            ...(finished_mentoring_items.length !== 0 && mentoring_items.length !== 0
              ? [{ label: 'Finished mentoring', items: finished_mentoring_items, expanded: false }]
              : []),

            ...(finished_mentoring_items.length !== 0 && mentoring_items.length === 0
              ? [
                  {
                    label: 'Finished mentoring',
                    items: [...finished_mentoring_items, ...smartGoalsItem, ...taskTrackerItem],
                    expanded: false,
                  },
                ]
              : []),

            ...(finished_coaching_items.length !== 0
              ? [{ label: 'Finished coaching', items: finished_coaching_items, expanded: false }]
              : []),

            // other menu items
            {
              label: 'Resources',
              routerLink: RESOURCES,
            },
            ...(user?.role === 'ME'
              ? [
                  {
                    label: 'Community',
                    routerLink: COMMUNITY,
                  },
                ]
              : []),

            ...(user?.role === 'ME' ? [{ label: 'Networking', routerLink: NETWORKING }] : []),

            ...(user?.role === 'MR'
              ? [
                  {
                    label: 'Mentor database',
                    routerLink: MENTORDATABASE,
                  },
                  { label: 'My mentor profile', routerLink: PROFILE },
                ]
              : []),
            ...(user?.role === 'CH' ? [{ label: 'My coach profile', routerLink: PROFILE }] : []),
            { label: 'Career planner', routerLink: CAREERPLANNER },
          ];
        });
      }
    });
  }

  closeBanner() {
    this.visibleBanner = !this.visibleBanner;
  }

  hideSidebar() {
    this.visibleSidebar = false;
  }
}
